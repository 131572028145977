.field-container {
    margin: 0 0 10px 0;
}

.field-container__label {
    display: inline-block;
    height: 22px;
    text-align: left;
    width: 130px;
    margin: 0 10px 0 0;
}

.field-container__input-text {
    width: 280px;
    height: 32px;
    border: 1px solid gray;
    border-radius: 6px;
    padding: 0 6px;
}

.field-container__input-text--short {
    width: 120px;
}

.field-container__input-text--medium {
    width: 170px;
}

.field-container__input-text--long {
    width: 380px;
}

.field-container__input-select {
    width: 280px;
    height: 32px;
    border: 1px solid gray;
    border-radius: 6px;
    padding: 0 6px;
}

.field-container__input-select--long {
    width: 384px;
}

.field-container__input-select--medium {
    width: 170px;
}

.field-container__input-select--short {
    width: 120px;
}

.drag-handle {
    cursor: move;
}

.styled-icon {
    background: #ffedc4;
    padding: 3px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: #ffb200;
    margin-right: 4px;
}

.delete-icon {
    color: #FF7169;
    vertical-align: middle;
    font-size: 20px;
}

.delete-icon:hover{
    color: #e5655e;
}

.lms-style {
    width: 1.2em;
    min-width: 1.2em;
    height: 1.2em;
    margin: 0;
    vertical-align: top;
    background-color: #F5F3F6;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #1E1E1E;
    box-shadow: 2px 2px 0px #1E1E1E;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

.lms-style:checked {
    background-color: #5D4B65;
    border-color: rgba(105, 108, 255, 0.08);
}

.lms-style:focus {
    border-color: #5D4B65;
    outline: 0;
    box-shadow: 2px 2px 0px #1E1E1E;
}

.lms-style:active {
    filter: brightness(90%);
}

.lms-style[type=radio] {
    border-radius: 50%;
}

.lms-style[type=checkbox] {
    border-radius: 0.25em;
}

.lms-style:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='1.5' fill='%23fff'/%3e%3c/svg%3e");
}

.lms-style:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.option-label {
    margin: 5px 0 0 10px;
    cursor: pointer;
}

.normal-style {
    width: 1.2em;
    height: 1.2em;
    margin: 1px 4px;
    vertical-align: top;
    background-color: #F5F3F6;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #1E1E1E;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.normal-style:checked {
    background-color: #5D4B65;
    border-color: rgba(105, 108, 255, 0.08);
}

.normal-style:focus {
    border-color: #5D4B65;
    outline: 0;
}

.normal-style:active {
    filter: brightness(90%);
}

.normal-style[type=radio] {
    border-radius: 50%;
}

.normal-style[type=checkbox] {
    border-radius: 0.25em;
}

.normal-style:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='1.5' fill='%23fff'/%3e%3c/svg%3e");
}

.normal-style:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.asterisk {
    color: #FF7169;
}
/*****************/
/* Modal-related */
/*****************/
.modal-overlay {
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-form {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 10px;
    width: 80%;
    overflow: hidden;
}

.modal-form-main-container {
    width: 35%;
    min-width: 600px;
}

.modal-form-container-section {
    margin: 20px 30px 20px 30px;
}

.modal-form__title {
    background: white;
    padding: 0 8px 8px 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.modal-form__layout {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50px auto 1fr 70px;
    grid-template-areas:
        "header"
        "precontent"
        "content"
        "footer";
    gap: 0;
    align-items: center;
}

.modal-form__section--header {
    grid-area: header;
}

.modal-form__section--precontent {
    grid-area: precontent;    
}

.modal-form__section--content {
    grid-area: content;
    height: 100%;
    margin: 20px 0 0 0;
    overflow: auto;
}

.modal-form__section--footer {
    grid-area: footer;
    padding-top: 10px;
}

.modal-form__btn-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
}

.modal-form__cancel-btn {
    background-color: #FA8E44;
    border-radius: 5px;
    color: white;
    padding: 14px 25px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    min-width: 60px;
    margin-left: 20px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.modal-form__cancel-btn:hover {
    background-color: #c87136;
    transform: translateY(-1px);
}

.modal-form__cancel-btn:active {
    background-color: #c87136;
}

.modal-form__ok-btn {
    background-color: #41A592;
    border-radius: 5px;
    color: white;
    padding: 14px 25px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    min-width: 60px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.modal-form__ok-btn:hover {
    background-color: #1c706d;
    transform: translateY(-1px);
}

.modal-form__ok-btn:active {
    background-color: #1c706d;
}

.modal-form__ok-btn--disabled {
    background-color: #969696;
}

.modal-form__ok-btn--disabled:hover {
    background-color: #969696;
    pointer-events: none;
}

.modal-form__ok-btn--neutral {
    background-color: #55acee;
    border-radius: 5px;
    color: white;
    padding: 14px 25px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    min-width: 60px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.modal-form__ok-btn--neutral:hover {
    background-color: #6fc6ff;
    transform: translateY(-1px);
}

.modal-form__ok-btn--neutral:active {
    background-color: #6fc6ff;
}

.modal-form__save-btn {
    background-color: #29A19C;
    border-radius: 5px;
    color: white;
    padding: 14px 25px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    min-width: 60px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.modal-form__save-btn:hover {
    background-color: #1c706d;
    transform: translateY(-1px);
}

.modal-form__save-btn:active {
    background-color: #1c706d;
}

.disabled-btn {
    background-color: #969696;
}

.modal-form__remove-btn {
    background-color: #FF7169;
    border-radius: 5px;
    color: white;
    padding: 14px 25px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    min-width: 60px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.modal-form__remove-btn:hover{
    background: #e5655e;
    transform: translateY(-1px);
}
/*****************/
/* Table-related */
/*****************/
.table-child {
    border-collapse: collapse;
    width: 100%;
}

.table-child-header {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.table-child-header--left {
    text-align: left;
}

.table-child-header--right {
    text-align: right;
}

.table-child-header--center {
    text-align: center;
}

.table-child-data {
    padding: 4px 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.table-child-data--left {
    text-align: left;
}

.table-child-data--right {
    text-align: right;
}

.table-child-data--center {
    text-align: center;
}
/*****************/
/* OSS-related */
/*****************/
.sync-with-oss__btn {
    border-radius: 5px;
    padding: 14px 25px;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    margin: 0 20px;
    color: #fff;
    position: relative;
    display: inline-block;
    cursor: pointer;
    background-color: #4B6289;
    min-width: 55px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.sync-with-oss__btn:hover {
    background-color: #34445f;
    transform: translateY(-1px);
}

.sync-with-oss__btn:active {
    background-color: #34445f;
}

.sync-with-oss__btn--disabled {
    background-color: #bdc4c9;
    pointer-events: none;
}
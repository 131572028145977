.main-container {
    width: 100%;
    height: 100%;
    background: url(./../../../../../Assets/Images/common/tile_background.png) center;
    background-size: cover;
}

.login-form {
    background-color: white;
    margin: auto;
    position: relative;
    text-align: center;
    top: 18%;
    width: 900px;
    height: 500px;
    border-radius: 10px;
}

.layout {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas:
        "logindisplay loginform";
    font-size: 14px;
    border-radius: 10px;
}

.section__login-display {
    grid-area: logindisplay;
    background: linear-gradient(to right,#afaaff, #f7ccff);
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.section__login-form {
    grid-area: loginform;
}

.login-msg {
    font-weight: 600;
    font-size: 18px;
    margin: 20px 0 0 0;
}

.hand-icon{
    color: #ffb200;
    font-size: 24px;
    vertical-align: sub;
}

.login-form__form {
    margin: 50px 0 0 0;
}

.login-form__title {
    display: block;
    font-size: 28px;
    margin: 80px 0 0 0;
    letter-spacing: 6px;
}

.login-form__input-text {
    outline: 0;
    width: 300px;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 8px;
    background: rgba(0, 0, 0, .04);
    border: none;
}

.login-form__button {
    outline: 0;
    background: #41A592;
    border-radius: 8px;
    border: none;
    width: 200px;
    padding: 15px;
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
    display: block;
    margin: 25px auto;
    font-family: Poppins;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.login-form__button--highlight:hover, .login-form__button--highlight:active, .login-form__button--highlight:focus {
    transform: translateY(-1px);
    background-color: #1c706d;
}

.forgot-link-container {
    margin: 25px 0 0 0;
}

.forgot-link {
    font-size: 15px;
    color: #797d85;
    text-decoration: none;
}

.forgot-link:hover{
    color: #594b65;
}

.learnful-image {
    max-width: 40%;
    max-height: 40%;
    margin-top: 60px;
}

.learnful-image-no-text{
    width: 270px;
    height: 270px;
}

.error-msg {
    color: red;
    margin-top: 20px;
}

@media screen and (max-width: 992px){
    .login-form{
        width: 450px;
    }
    .layout {
        grid-template-columns: 100%;
        grid-template-areas:
            "loginform";
    }
    .section__login-display{
        display: none;
    }
    .login-container{
        width: 100%;
    }
    .main-container{
        height: 100%;
    }
}

@media screen and (max-width: 440px) {
    .login-container {
        width: 100%;
    }
    .login-form {
        max-width: 350px;
    }
}
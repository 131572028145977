@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Comfortaa:300,400,500,600,700,800");

html {
    height: 100%;
    min-height: 100%;
    /* Uncomment if we want for text to be unselectable */
    /*-webkit-user-select: none; /* Safari */
    /*-moz-user-select: none; /* Firefox */
    /*-ms-user-select: none; /* IE10+/Edge */
    /*user-select: none;*/

    font-family: Poppins;
}

body {
    margin: 0;
    height: 100%;
    min-height: 100%;
    background-color: #594b65;
    font-family: Poppins;
}
.login-type-select {
    background-color: white;
    margin: auto;
    position: relative;
    text-align: center;
    top: 18%;
    width: 800px;
    border-radius: 10px;
}

.login-type-select--multiple {
    padding: 30px 30px 50px 30px;
    border-radius: 10px;
}

.learnful-image {
    width: 180px;
    margin-bottom: 10px;
}

.select-icon{
    color: #ffb200;
    font-size: 30px;
}

.login-type-select-title{
    font-weight: 600;
    font-size: 18px;
    margin: 0 0 15px 0;
}

.login-type-select__msg--multiple {
    display: inline-block;
    text-align: left;
    line-height: 26px;
    margin-top: 10px;
}

.login-type-select__role {
    margin: 20px 0 0 0;
    padding: 10px;
    font-size: 14px;
    border-radius: 8px;
    background: rgba(0, 0, 0, .04);
    border: none;
}

.login-type-select--none {
    padding: 90px 100px 0 100px;
}

.login-type-select__msg--none {
    display: inline-block;
    text-align: left;
    line-height: 26px;
}

.login-form__button {
    outline: 0;
    background: #41A592;
    border: none;
    border-radius: 8px;
    padding: 10px;
    color: #FFFFFF;
    font-size: 14px;
    width: 100px;
    cursor: pointer;
    margin: 0 0 0 20px;
    font-family: Poppins;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.login-form__button--highlight:hover, .login-form__button--highlight:active, .login-form__button--highlight:focus {
    transform: translateY(-1px);
    background-color: #1c706d;
}

@media screen and (max-width: 992px) {
    .login-type-select {
        width: 450px;
    }
    .login-form__button{
        margin-top: 20px;
    }
}

@media screen and (max-width: 440px) {
    .login-type-select {
        width: 350px;
    }
    .login-type-select__role{
        width: 280px;
    }
}